/* You can add global styles to this file, and also import other style files */

@font-face
    font-family: 'Futura Bold Oblique'
    src: url('./assets/fonts/FuturaStd-BoldOblique.woff2') format('woff2'), url('./assets/fonts/FuturaStd-BoldOblique.woff') format('woff')

@font-face
    font-family: 'Futura Heavy'
    src: url('./assets/fonts/FuturaStd-Heavy.woff2') format('woff2'), url('./assets/fonts/FuturaStd-Heavy.woff') format('woff')

@font-face
    font-family: 'Futura Medium Oblique'
    src: url('./assets/fonts/FuturaStd-MediumOblique.woff2') format('woff2'), url('./assets/fonts/FuturaStd-MediumOblique.woff') format('woff')

@font-face
    font-family: 'Century Gothic'
    src: url("./assets/fonts/CenturyGothic.woff") format('woff'), url("./assets/fonts/CenturyGothic.ttf") format('truetype')

$main-color: #fea621

body
    margin: 0
    background-color: black
    color: $main-color
    font-size: 24px
    overflow: hidden
    height: 100%

.user-html
    font-size: 18px
    font-weight: normal
    line-height: normal
    color: black

.user-html p
    margin: 0
    padding: 0

.stiglechner-menu
    position: absolute
    top: 70px
    left: 1430px
    width: 440px
    height: 940px
    z-index: 100

    color: black
    font-family: 'Futura Medium Oblique'
    font-size: 37px
    text-align: center

    *
        display: block
    & > div:nth-child(2)
        position: absolute
        top: 540px
        left: 0
    .title-menu
        font-size: 45px
        margin-bottom: 40px
    .soup
        margin-bottom: 35px
    .title
        font-family: 'Futura Heavy'
    .description
        white-space: pre
        margin-bottom: 35px
    .price
        font-family: 'Futura Bold Oblique'
        font-size: 40px

.line
    width: 100%
    height: 2px
    background: linear-gradient(90deg, #1a171b 10%, #c3c3c3, #1a171b 90%)
    margin-top: 50px
    margin-bottom: 50px

@keyframes shopiq-logo-animation
    0%
        transform: scale( 1 )
    25%
        transform: scale( 1.15 )
    40%
        transform: scale( 1 )
    55%
        transform: scale( 1.15 )
    80%
        transform: scale( 1 )
    100%
        transform: scale( 1 )


.shopiq-background
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    background-color: #333333
    padding: 54px 43px
    pointer-events: none
    user-select: none

    .shopiq-logo
        margin-top: 20vh
        text-align: center

        img
            display: inline-block
            height: 30vh
            animation: shopiq-logo-animation
            animation-delay: 1.5s
            animation-timing-function: ease-in-out
            animation-duration: 2s
            animation-iteration-count: 3

    .shopiq-slogan
        position: absolute
        bottom: 2vh
        right: 2vh
        height: 6vh

@media (orientation: landscape)
    .shopiq-background
        .shopiq-logo
            height: 32.777777778vh
        .shopiq-slogan
            height: 6.111111111vh

@media (orientation: portrait)
    .shopiq-background
        .shopiq-logo
            height: 32.777777778vw
        .shopiq-slogan
            height: 6.111111111vw
